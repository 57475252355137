<div>
  <div class="page-hero" id="page-hero">
    <div class="padding d-flex">
      <div class="page-title">
        <h2 class="text-md text-highlight">Product Providers</h2>
        <small class="text-muted">information about proudct price providers</small>
      </div>
    </div>
  </div>
  <div class="page-content" id="page-content">
    <div class="padding">
      <div class="row">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-theme table-row v-middle">
              <thead>
                <tr>
                  <th class="text-muted">Provider</th>
                  <th class="text-muted">Condation</th>
                  <th class="text-muted">Network</th>
                  <th class="text-muted">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of rows" provider-grade-row [rowData]="row"></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
